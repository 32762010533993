import React from 'react';

import Header from '../../components/partner/Header';
import MenuBar from '../../components/Home/MenuBar';
import PartnersPager from '../../components/partner/PartnersPager';
import Ecosystem from '../../components/Home/v2/Ecosystem';
import Footer from '../../components/Home/Footer';
import { Container, Content } from '../../components/Home/v2/StyledComponents';
import Layout from '../../layouts/index';

const EcosystemPage = () => (
  <Layout routeSlug="EcosystemPage">
    <Container>
      <MenuBar />
      <Header />
      <PartnersPager />
      <Content><Ecosystem /></Content>
      <Footer />
    </Container>
  </Layout>
);

export default EcosystemPage;
