import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import isMobile from '../../../services/isMobile';
import routesMap from '../../../Routes';
import { trackEvent } from '../../../services/analytics';
import Dots from './Dots';
import { mobileThresholdPixels, fontSizes, colors, margins, Title2, Subtitle } from './StyledComponents';

import shopPark from '../../../assets/partners/shopPark.png';
import kpi from '../../../assets/partners/kpi.png';
import heart from '../../../assets/partners/heart.png';
import tilliXvous from '../../../assets/partners/tilliXvous.png';

const P = styled.p`
  font-size: ${fontSizes.m};
  margin: 0px;
  ${props => props.pointer && 'cursor: pointer'};
`;

const Texts = [
  {
    index: 1,
    title: 'Couverture du parc boutique',
    image: shopPark,
    alt: '',
    sections: [
      {
        title: 'Communiquer en magasin',
        content: ['PLV en magasin', 'Affichage en cabines'],
      },
      {
        title: 'Offrir un service en boutique',
        content: ['Plateforme de réservation en caisse', 'Incentive vendeur'],
      },
    ],
  },
  {
    index: 2,
    title: 'Board KPI',
    image: kpi,
    alt: '',
    sections: [
      {
        title: 'Reporting des typologies de retouches',
        content: [<span>Comprendre la morphologie de vos clients et ajuster <br />vos futures collections</span>],
      },
      {
        title: 'Utilisation des codes promos',
        content: ['Nombre de codes promo utilisés', 'Budget dépensé'],
      },
      {
        title: 'Contrôle qualité',
        content: ['Notes clients', 'Commentaires clients'],
      },
    ],
  },
  {
    index: 3,
    title: 'Conseiller votre client',
    image: heart,
    alt: '',
    sections: [
      {
        title: 'Éduquer le client sur la page produit',
        content: [
          'Informer des retouches possibles sur chaque vêtement',
          'Rassurer le client sur l’accompagnement des retouches',
        ],
      },
      {
        title: 'Communiquer à vos clients votre nouvelle offre',
        content: [
          'Page dédiée au fonctionnement de Tilli',
          <span>
            Intégrer les dimensions : durabilité du vêtement, du sur-mesure et <br />du coup de coeur à faire ajuster
          </span>,
        ],
      },
      {
        title: 'Compléter l’offre avec notre partenaire Fitle',
        content: [
          'Grâce à Fitle vous pushez à votre client la bonne taille',
          'Grâce à Tilli, vous ajustez les derniers détails pour un fit parfait',
        ],
      },
    ],
  },
  {
    index: 4,
    title: 'Éduquer nos clients ensemble',
    image: tilliXvous,
    alt: '',
    sections: [
      {
        title: 'Collab',
        content: <P>
          {!isMobile() && 'Voir la collab '}<Link
            key={'collabEliseChalmin'}
            style={{ textDecoration: 'underline', color: 'inherit' }}
            to={routesMap.LaRubrique_CollabEliseChalmin.url}
            onClick={() => trackEvent('click', 'collabEliseChalmin-ecoSystem')}
          >{isMobile() ? 'Voir la collab Elise Chalmin' : 'Elise Chalmin'}</Link>,
        </P>,
      },
      {
        title: 'Évènement',
        content: <P>
          Installé proche des cabines d’essayages d’un évènement,
          un couturier peut vous apporter une vraie force de vente
          en conseillant votre clientèle et en épinglant sur place pour
          offrir à vos clients des pièces sur-mesures.
        </P>,
      },
    ],
  },
];

const Container = styled.div`
  margin-bottom: ${margins.xl};
  color: ${colors.navy};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px ${margins.s};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -${margins.m};
  }
`;

const BlockContainer = styled.div`
  display: flex;
  margin-top: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: ${margins.l};
  }
`;

const Img = styled.img`
  width: 35%;
  height: 30%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 55%;
    height: 50%;
  }
`;

const TextContainer = styled.div`
  margin: 0px ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const Title3 = styled.h3`
  font-family: Gotham;
  font-size: ${fontSizes.l};
  text-transform: uppercase;
`;

const SectionContainer = styled.div`margin: ${margins.m};`;

const SectionTitle = styled.p`
  font-weight: 500;
  font-size: ${fontSizes.l};
  letter-spacing: 1.1px;
  margin: 0px 0px ${margins.m} 0px;
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${margins.s};
  background-color: ${colors.navy};
  color: ${colors.white};
  font-size: ${fontSizes.m};
  width: 100%;
  margin: auto;
  margin-top: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    left: 0px;
    margin: ${margins.l} 0px 0px -${margins.s};
  }
`;

const Span1 = styled.span`
  font-family: Gotham;
  text-transform: uppercase;
`;

const Span2 = styled.span`font-family: Roboto;`;

const Block = (index, title, image, alt, sections) => (
  <BlockContainer>
    <Img src={image} alt={alt} />
    <TextContainer>
      <Title3>{`${index} / ${title}`}</Title3>
      {sections.map(section =>
        <SectionContainer key={section.title}>
          <SectionTitle>{section.title}</SectionTitle>
          {Array.isArray(section.content) ?
            section.content.map(item => <P key={item}>{'- '}{item}</P>) :
            section.content
          }
        </SectionContainer>,
      )}
    </TextContainer>
  </BlockContainer>
);

const Ecosystem = () => (
  <Container>
    <Row><Dots big /></Row>
    <Title2 big>Créez un écosystème responsable avec Tilli</Title2>
    <Subtitle>en ajoutant des briques à l’ensemble de votre parcours</Subtitle>
    {Texts.map(block => Block(block.index, block.title, block.image, block.alt, block.sections))}
    <CallToAction>
      <Span1>Contactez-nous !</Span1><br />
      <Span2>bdelabouchere@tilli.fr</Span2>
    </CallToAction>
  </Container>
);

export default Ecosystem;
